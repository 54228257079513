.gain-energy-container {
    position: absolute;
    width: 45px;
    height: 55px;
    transform: translate(-50%, -50%);
}

@keyframes GainEnergyAnimation {
    0%, 100% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(-10deg);
    }
    75% {
        transform: rotate(10deg);
    }
}

.gain-energy-animation-container {
    position: absolute;
    width: 100%;
    height: 100%;
}

.gain-energy-pop-up-animation {
    position: absolute;
    width: 100%;
    height: 100%;
}

.gain-energy-shake-animation {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: GainEnergyAnimation 0.5s ease-in-out infinite;
}

.gain-energy-image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 22px;
    height: 22px;
    opacity: 0;
    pointer-events: none;
    user-select: none;
    transform: translate(-50%, -50%)  scale(100%, 100%);
    background-image: url('../images/backgrounds/energy_icon.png');
    background-size: cover;
}