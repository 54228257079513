.guide-popup-mission-content-title {
    position: absolute;
    top: 15%;
    left: 70%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    overflow: auto;
    text-align: left;
    line-height: 100%;
    margin: 0px;
    width: 50%;
    height: 10%;
    font-family: "mishmash";
    color: #5CFFFF;
    pointer-events: none;
    user-select: none;
}

.guide-popup-mission-content-text {
    position: absolute;
    top: 45%;
    left: 68%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    overflow: auto;
    text-align: left;
    line-height: 100%;
    margin: 0px;
    width: 46%;
    height: 55%;
    font-family: "Arial";
    color: white;
    pointer-events: none;
    user-select: none;
}

.guide-popup-mission-image {
    position: absolute;
    width: 172px;
    height: 312px;
    left: 23%;
    top: 46%;
    transform: translate(-50%, -50%);
    user-select: none;
}