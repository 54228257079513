.guide-popup-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
}

.guide-popup-mask {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    user-select: none;
}

.guide-popup-main-container {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 519px;
    height: 416px;
}

.guide-popup-main-background {
    width: 100%;
    height: 100%;
    user-select: none;
}

.guide-popup-end-guide-button {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.guide-popup-prev-button {
    position: absolute;
    top: 80%;
    left: 45%;
    transform: translate(-50%, -50%);
}

.guide-popup-next-button {
    position: absolute;
    top: 80%;
    left: 55%;
    transform: translate(-50%, -50%);
}