.main-menu-empty-hint-container {
    position: absolute;
    width: 95px;
    height: 30px;
    transform: translate(-50%, -50%);
}

.main-menu-empty-hint-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
    text-align: center;
    line-height: 100%;
    margin: 0px;
    color: rgba(32,84,106,255);
    width: 100%;
    height: 20px;
    font-family: "mishmash";
    pointer-events: none;
    user-select: none;
}