.resource-hover-container {
    position: relative;
    width: 150px;
    height: 86px;
    z-index: 10;
}

.resource-hover-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
}

.resource-hover-title-text {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    text-align: left;
    line-height: 100%;
    margin: 0px;
    color: white;
    width: 90%;
    height: 12px;
    font-family: "mishmash";
    pointer-events: none;
    user-select: none;
}

.resource-hover-context-text {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
    text-align: left;
    line-height: 100%;
    margin: 0px;
    color: white;
    width: 90%;
    height: 10px;
    font-family: "mishmash";
    pointer-events: none;
    user-select: none;
}