.guide-popup-circle-content-title {
    position: absolute;
    top: 15%;
    left: 30%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    overflow: auto;
    text-align: left;
    line-height: 100%;
    margin: 0px;
    width: 50%;
    height: 10%;
    font-family: "mishmash";
    color: #5CFFFF;
    pointer-events: none;
    user-select: none;
}

.guide-popup-circle-content-text {
    position: absolute;
    top: 45%;
    left: 26%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    overflow: auto;
    text-align: left;
    line-height: 100%;
    margin: 0px;
    width: 43%;
    height: 55%;
    color: white;
    pointer-events: none;
    user-select: none;
}

.guide-popup-circle-image {
    position: absolute;
    width: 270px;
    height: 249px;
    left: 72.5%;
    top: 48%;
    transform: translate(-50%, -50%);
    user-select: none;
}

.guide-popup-circle-arrow-image {
    position: absolute;
    width: 114px;
    height: 45px;
    left: 60%;
    top: 20%;
    opacity: 0.7;
    transform: translate(-50%, -50%);
    user-select: none;
}