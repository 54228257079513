.withdraw-popup-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
}

.withdraw-popup-mask {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    user-select: none;
}

.withdraw-popup-main-container {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 371px;
    height: 217px;
}

.withdraw-popup-main-background {
    width: 100%;
    height: 100%;
    user-select: none;
}

.withdraw-popup-confirm-button {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.withdraw-popup-amount-container {
    position: absolute;
    width: 226px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.withdraw-popup-amount-background {
    width: 100%;
    height: 100%;
    user-select: none;
}

.withdraw-popup-title-text{
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    text-align: center;
    line-height: 20px;
    margin: 0px;
    width: 80%;
    height: 20px;
    font-family: "mishmash";
    color: white;
    user-select: none;
}

.withdraw-popup-amount-text {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
    text-align: center;
    line-height: 10px;
    margin: 0px;
    width: 80%;
    height: 10px;
    font-family: "mishmash";
    color: red;
    user-select: none;
}

.withdraw-popup-amount-input {
    position: absolute;
    background: none; 
    border: none;     
    outline: none;    
    color: #fff;      
    text-align: center;
    font-size: 12px;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 10px;
    user-select: none;
}

.withdraw-popup-titanium-resource-container {
    position: absolute;
    width: 50px;
    height: 30px;
    left: 50%;
    top: 37%;
    user-select: none;
    transform: translate(-50%, -50%);
}

.withdraw-popup-titanium-resource-display-image {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    user-select: none;
}

.withdraw-popup-titanium-resource-display-text {
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    text-align: center;
    line-height: 100%;
    margin: 0px;
    color: white;
    width: 35px;
    height: 12px;
    font-family: "mishmash";
    pointer-events: none;
    user-select: none;
}


input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input[type="number"] {
  -moz-appearance: textfield;
}