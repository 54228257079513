.spend-resource-container {
    position: absolute;
    width: 35px;
    height: 35px;
    transform: translate(-50%, -50%);
}

.spend-resource-animation-container {
    position: absolute;
    width: 100%;
    height: 100%;
}

.spend-resource-image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
}

.spend-resource-amount-animation-container {
    position: absolute;
    width: 40px;
    height: 12px;
    transform: translate(-50%, -50%);
}