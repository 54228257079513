.redeem-menu-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 519px;
    height: 416px;
}

.redeem-menu-background {
    width: 100%;
    height: 100%;
    user-select: none;
}

.redeem-resource-title-text {
    position: absolute;
    top: 12%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    text-align: center;
    line-height: 15px;
    margin: 0px;
    width: 80%;
    height: 15px;
    font-family: "mishmash";
    color: white;
    user-select: none;
}

.redeem-menu-grid{
    width: 480px;
    height: 280px;
    top: 52%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.redeem-menu-guide-button {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
}