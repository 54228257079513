.player-info-container{
    position: absolute;
    width: 350px;
    height: 58px;
    left: 0px;
    top: 0px;
}

.player-info-level-container, .player-info-energy-container, .player-info-xp-container {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 38px;
    user-select: none;
}

.player-info-level-container {
    left: 20%;
    top: 50%;
}

.player-info-xp-container {
    left: 50%;
    top: 50%;
}

.player-info-energy-container {
    left: 80%;
    top: 50%;
}
