.left {
    width: 190px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background-image: url('../images/backgrounds/bg.png');
    z-index: 5;
}

.left-top, .left-middle, .left-bottom {
    width: 190px;
    background-size: contain;
}

.left-top {
    height: 190px;
    background-image: url('../images/backgrounds/left_top.png');
    background-repeat: no-repeat;
}

.left-middle {
    flex-grow: 1;
    background-image: url('../images/backgrounds/left_mid.png');
    background-repeat: repeat-y;
}

.left-bottom {
    height: 190px;
    background-image: url('../images/backgrounds/left_bottom.png');
    background-repeat: no-repeat;
}

.left-creature-grid{
    position: absolute;
    transform: translate(-50%, -50%);
    width: 152px;
    height: calc(100% - 110px);
    top: 50%;
    left: 45%;
}

.left-creature-page-selector{
    position: absolute;
    width: 170px;
    height: 40px;
    bottom: 0px;
    left: 0px;
}

.left-corner-bar {
    position: absolute;
    width: 60px;
    height: 60px;
    bottom: 0px;
    right: -2px;
    user-select: none;
}

.left-middle-bar {
    position: absolute;
    width: 32px;
    height: 125px;
    top: 50%;
    right: -20px;
    transform: translate(-50%, -50%);
    user-select: none;
}