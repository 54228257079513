.account-info-container{
    position: absolute;
    width: 202px;
    height: 58px;
    left: calc(max(100%, 960px) - 210px);
    top: 0px;
}

.account-info-background {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    user-select: none;
}

.account-info-account-text, .account-info-key-text, .account-info-player-id-text{
    position: absolute;
    font-size: 8px;
    margin: 0;
    color: white;
    left: 2.5%;
    width: 95%;
    height: 8px;
    font-family: "mishmash";
    color: #5CFFFF;
    white-space: break-spaces;
    pointer-events: none;
    user-select: none;
}

.account-info-account-text{
    top: 25%;
}

.account-info-key-text{
    top: 50%;
}

.account-info-player-id-text{
    top: 75%;
}