.program-tutorial-container {
    position: absolute;
    left: -160px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 100px;
    z-index: 20;
    user-select: none;
    pointer-events: none;
}

.program-tutorial-background {
    position: absolute;
    width: 155px;
    height: 86px;
    left: 30%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@keyframes TutorialAnimation {
    from { background-position: 0px; }
    to { background-position: -2704px; }
}

.program-tutorial-animation {
    position: absolute;
    width: 208px;
    height: 101px;
    left: 80%;
    top: 50%;
    transform: translate(-50%, -50%) scale(50%, 50%);
    animation: TutorialAnimation 1.5s steps(13) infinite;
    background-image: url("../images/Animations/tutorial_program.png");
    background-size: cover;
    user-select: none;
}

.program-tutorial-title {
    position: absolute;
    left: 30%;
    top: 22%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    text-align: center;
    line-height: 12px;
    margin: 0px;
    width: 140px;
    height: 12px;
    font-family: "mishmash";
    color: white;
    user-select: none;
}

.program-tutorial-description {
    position: absolute;
    left: 30%;
    top: 57%;
    transform: translate(-50%, -50%);
    font-size: 8px;
    text-align: left;
    line-height: 8px;
    margin: 0px;
    width: 140px;
    height: 50px;
    font-family: "mishmash";
    color: white;
    user-select: none;
}