.guide-popup-program-content-title {
    position: absolute;
    top: 15%;
    left: 30%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    overflow: auto;
    text-align: left;
    line-height: 100%;
    margin: 0px;
    width: 50%;
    height: 10%;
    font-family: "mishmash";
    color: #5CFFFF;
    pointer-events: none;
    user-select: none;
}

.guide-popup-program-content-text {
    position: absolute;
    top: 45%;
    left: 28%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    overflow: auto;
    text-align: left;
    line-height: 100%;
    margin: 0px;
    width: 46%;
    height: 55%;
    color: white;
    pointer-events: none;
    user-select: none;
}

.guide-popup-program-image {
    position: absolute;
    width: 154px;
    height: 325px;
    left: 78%;
    top: 45%;
    transform: translate(-50%, -50%);
    user-select: none;
}

.guide-popup-program-arrow-image {
    position: absolute;
    width: 265px;
    height: 71px;
    left: 66.5%;
    top: 24%;
    opacity: 0.7;
    transform: translate(-50%, -50%);
    user-select: none;
}