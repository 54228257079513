.upgrade-popup-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
}

.upgrade-popup-mask {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    user-select: none;
}

.upgrade-popup-main-container {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 371px;
    height: 282px;
}

.upgrade-popup-main-background {
    width: 100%;
    height: 100%;
    user-select: none;
}

.upgrade-popup-creature-image {
    position: absolute;
    top: 29.9%;
    left: 26.8%;
    transform: translate(-50%, -50%);
    width: 85px;
    height: 85px;
    user-select: none;
}

.upgrade-popup-resources-info-grid{
    width: 156px;
    height: 57px;
    top: 35%;
    left: 62.5%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.upgrade-popup-speed-button {
    position: absolute;
    top: 65%;
    left: 23%;
    transform: translate(-50%, -50%)  skew(-20deg);
}

.upgrade-popup-efficiency-button {
    position: absolute;
    top: 65%;
    left: 49%;
    transform: translate(-50%, -50%) skew(-20deg);
}

.upgrade-popup-productivity-button {
    position: absolute;
    top: 65%;
    left: 75%;
    transform: translate(-50%, -50%) skew(-20deg);
}

.upgrade-popup-confirm-button {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.upgrade-popup-title-text{
    position: absolute;
    top: 16%;
    left: 63%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    text-align: left;
    line-height: 15px;
    margin: 0px;
    width: 40%;
    height: 15px;
    font-family: "mishmash";
    color: white;
    user-select: none;
}

.upgrade-popup-bot-name-text{
    position: absolute;
    top: 21.5%;
    left: 63%;
    transform: translate(-50%, -50%);
    font-size: 10px;
    text-align: left;
    line-height: 10px;
    margin: 0px;
    width: 40%;
    height: 10px;
    font-family: "mishmash";
    color: white;
    user-select: none;
}

.upgrade-popup-upgrade-title-text{
    pointer-events: none;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%) skew(20deg);
    font-size: 8px;
    text-align: center;
    line-height: 8px;
    margin: 0px;
    width: 80%;
    height: 8px;
    font-family: "mishmash";
    color: white;
    user-select: none;
}

.upgrade-popup-upgrade-value-text{
    pointer-events: none;
    position: absolute;
    top: 81%;
    left: 53%;
    transform: translate(-50%, -50%) skew(20deg);
    font-size: 12px;
    text-align: center;
    line-height: 12px;
    margin: 0px;
    width: 80%;
    height: 12px;
    font-family: "mishmash";
    color: white;
    user-select: none;
}

.upgrade-popup-button-icon {
    position: absolute;
    width: 13px;
    height: 13px;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    user-select: none;
}

.upgrade-popup-button-text {
    position: absolute;
    top: 50%;
    left: 65%;
    transform: translate(-50%, -50%);
    font-size: 13px;
    text-align: center;
    line-height: 100%;
    margin: 0px;
    width: 50%;
    height: 13px;
    font-family: "mishmash";
    color: white;
    pointer-events: none;
    user-select: none;
}