.parellogram-toggle-image-button-container{
  width: 100%;
  height: 100%;
}

.parellogram-toggle-image-button, .parellogram-toggle-image-button-disabled {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  user-select: none;
}

.parellogram-toggle-image-button-disabled:hover{
  cursor: default;
}
  
.parellogram-toggle-image {
  display: block;
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) skew(20deg);
  user-select: none;
}