.confirm-popup-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
}

.confirm-popup-mask {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    user-select: none;
}

.confirm-popup-main-container {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 371px;
    height: 217px;
}

.confirm-popup-main-background {
    width: 100%;
    height: 100%;
    user-select: none;
}

.confirm-popup-ok-button {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.confirm-popup-title-text-red, .confirm-popup-title-text-green{
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    text-align: center;
    line-height: 20px;
    margin: 0px;
    width: 80%;
    height: 20px;
    font-family: "mishmash";
    user-select: none;
}

.confirm-popup-title-text-red{
    color: #BC2115;
}

.confirm-popup-title-text-green{
    color: #5FFF5F;
}

.confirm-popup-description-text {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
    text-align: center;
    line-height: 10px;
    margin: 0px;
    width: 80%;
    height: 50px;
    font-family: "mishmash";
    color: white;
    user-select: none;
}