.main {
    height: 100%;
    width: calc(100% - 190px * 2);
    flex: 1;
    background-color: lightgreen;
    background-image: url('../images/backgrounds/menu_bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    position: relative;
    /* z-index: 3; */
}

.main-content {
    width: fit-content;
    height: 512px;
    width: 550px;
    position: relative;
}

.main-info-container{
    position: absolute;
    height: 125px;
    width: 203px;
}

.main-info-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
}

.main-circle-container {
    position: absolute;
    height: 486px;
    width: 488px;
    left: 31px;
}

.main-circle-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
}

@keyframes unlockFrames {
    from { background-position: 0px; }
    to { background-position: -7800px; }
}

.main-menu-program-container {
    position: absolute;
    width: 406px;
    height: 406px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: 10;
}

.main-bot-unlock-animation, .main-bot-upgrade-animation {
    position: absolute;
    width: 780px;
    height: 780px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(50%, 50%);
    background-size: cover;
    animation: unlockFrames 1s steps(10);
    background-image: url('../images/Animations/Unlock.png'); 
}

.main-redeem{
    position: absolute;
    top: 85%;
    left: 85%;
    transform: translate(-50%, -50%);
}