.program-hover-container {
    position: absolute;
    width: 94px;
    height: 70px;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.program-hover-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
}

.program-hover-resource-grid{
    position: absolute;
    transform: translate(-50%, -50%);
    width: 88px;
    height: 64px;
    top: 50%;
    left: 50%;
    pointer-events: none;
}