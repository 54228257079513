.new-program-animation-container{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
}

.new-program-animation-mask {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.new-program-animation-program-container {
    position: absolute;
    width: 165px;
    height: 125px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@keyframes NewProgramAnimation {
    from { background-position: 0px; }
    to { background-position: -10230px; }
}

.new-program-animation-main-animation {
    position: absolute;
    width: 330px;
    height: 250px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(50%, 50%);
    animation: NewProgramAnimation 2s steps(31) forwards;
    background-image: url("../images/Animations/NewProgram.png");
    background-size: cover;
}

@keyframes SpinAnimation {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
}

.new-program-animation-spin-image {
    position: absolute;
    width: 250px;
    height: 250px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: SpinAnimation 5s linear infinite;
    user-select: none;
}