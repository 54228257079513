.image-button, .image-button-disabled {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
}

.image-button-disabled:hover{
  cursor: default;
}
  
.image-button img, .image-button-disabled img {
  display: block;
  width: 100%;
  height: 100%;
  user-select: none;
}