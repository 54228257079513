.reboot-popup-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
}

.reboot-popup-mask {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    user-select: none;
}

.reboot-popup-main-container {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 371px;
    height: 217px;
}

.reboot-popup-main-background {
    width: 100%;
    height: 100%;
    user-select: none;
}

.reboot-popup-confirm-button {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.reboot-popup-cost-container {
    position: absolute;
    width: 100px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.reboot-popup-cost-background {
    width: 100%;
    height: 100%;
    user-select: none;
}

.reboot-popup-cost-icon {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
    user-select: none;
}

.reboot-popup-title-text{
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    text-align: center;
    line-height: 20px;
    margin: 0px;
    width: 80%;
    height: 20px;
    font-family: "mishmash";
    color: white;
    user-select: none;
}

.reboot-popup-subtitle-text {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
    text-align: center;
    line-height: 10px;
    margin: 0px;
    width: 80%;
    height: 10px;
    font-family: "mishmash";
    color: white;
    user-select: none;
}

.reboot-popup-cost-text {
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
    font-size: 10px;
    text-align: left;
    line-height: 10px;
    margin: 0px;
    width: 40%;
    height: 10px;
    font-family: "mishmash";
    color: white;
    user-select: none;
}