.main-current-frame-container {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 174px;
    height: 124px;
}

.main-current-frame-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    user-select: none;
}

.main-selecting-frame-container {
    position: absolute;
    width: 397px;
    height: 396px;
    left: 46px;
    top: 44px;
}

@keyframes selecting-shining {
    0% { opacity: 1; }
    50% { opacity: 0.7; }
    100% { opacity: 1; }
}

.main-selecting-frame-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: selecting-shining 1s infinite ease-in-out;
    user-select: none;
}