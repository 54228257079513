.new-program-container {
    position: relative;
    width: 168px;
    height: 89px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.new-program-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
}

.new-program-button {
    position: absolute;
    top: 73%;
    left: 67%;
    transform: translate(-50%, -50%);
}

.new-program-title-text {
    position: absolute;
    top: 25%;
    left: 67%;
    transform: translate(-50%, -50%);
    font-size: 13px;
    text-align: center;
    line-height: 100%;
    margin: 0px;
    width: 60%;
    height: 13px;
    font-family: "mishmash";
    color: white;
    pointer-events: none;
    user-select: none;
}

.new-program-title-2-text {
    position: absolute;
    top: 45%;
    left: 67%;
    transform: translate(-50%, -50%);
    font-size: 13px;
    text-align: center;
    line-height: 100%;
    margin: 0px;
    width: 60%;
    height: 13px;
    font-family: "mishmash";
    color: white;
    pointer-events: none;
    user-select: none;
}

.new-program-icon-image {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 73%;
    left: 57%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    user-select: none;
}

.new-program-button-text {
    position: absolute;
    top: 73%;
    left: 72%;
    transform: translate(-50%, -50%);
    font-size: 13px;
    text-align: center;
    line-height: 100%;
    margin: 0px;
    width: 20%;
    height: 13px;
    font-family: "mishmash";
    color: white;
    pointer-events: none;
    user-select: none;
}