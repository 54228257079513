.gain-titanium-resource-container {
    position: absolute;
    width: 250px;
    height: 250px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.gain-titanium-resource-animation {
    position: absolute;
    width: 500px;
    height: 500px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(50%, 50%);
    background-size: cover;
    background-image: url('../images/Animations/TitaniumScore.png');
    animation: titaniumScoreFrames 1s steps(12) forwards;
}

@keyframes titaniumScoreFrames {
    from { background-position: 0px; }
    to { background-position: -6000px; }
  }
  