.player-info-display-container {
    position: absolute;
    width: 100%;
    height: 100%;
}

.player-info-display-background {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 38px;
    user-select: none;
}

.player-info-display-icon {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 3%;
    top: 50%;
    width: 34px;
    height: 35px;
    user-select: none;
}

.player-info-display-title-text {
    position: absolute;
    font-size: 8px;
    margin: 0;
    color: white;
    text-align: left;
    top: 20%;
    left: 28%;
    width: 40px;
    height: 8px;
    font-family: "mishmash";
    white-space: break-spaces;
    pointer-events: none;
    user-select: none;
}

.player-info-display-amount-text {
    position: absolute;
    font-size: 8px;
    margin: 0;
    color: white;
    text-align: center;
    top: 59%;
    left: 24%;
    width: 40px;
    height: 8px;
    font-family: "mishmash";
    white-space: break-spaces;
    pointer-events: none;
    user-select: none;
}

.player-info-display-hover-range {
    position: relative;
    width: 100%;
    height: 100%;
}

.player-info-display-hover-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 100%;
    left: -10%;
    user-select: none;
}