.resource-display-container {
    position: relative;
    width: 80px;
    height: 40px;
}

.resource-display-hover-range {
    position: relative;
    width: 100%;
    height: 100%;
}

.resource-display-background {
    position: absolute;
    width: 45px;
    height: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
}

.resource-display-image {
    position: absolute;
    top: 50%;
    left: 15%;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
    user-select: none;
}

.resource-display-text {
    position: absolute;
    top: 50%;
    left: 54%;
    transform: translate(-50%, -50%);
    font-size: 8px;
    text-align: center;
    line-height: 100%;
    margin: 0px;
    color: white;
    width: 40px;
    height: 8px;
    font-family: "mishmash";
    pointer-events: none;
    user-select: none;
}

.resource-display-hover-container {
    position: absolute;
    top: 100%;
    left: -10%;
    user-select: none;
}