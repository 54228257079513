.guide-popup-resource-content-title {
    position: absolute;
    top: 28%;
    left: 30%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    overflow: auto;
    text-align: left;
    line-height: 100%;
    margin: 0px;
    width: 50%;
    height: 10%;
    font-family: "mishmash";
    color: #5CFFFF;
    pointer-events: none;
    user-select: none;
}

.guide-popup-resource-content-text {
    position: absolute;
    top: 57%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    overflow: auto;
    text-align: left;
    line-height: 100%;
    margin: 0px;
    width: 90%;
    height: 55%;
    color: white;
    pointer-events: none;
    user-select: none;
}

.guide-popup-resource-image {
    position: absolute;
    width: 455px;
    height: 55px;
    left: 50%;
    top: 15%;
    opacity: 0.7;
    transform: translate(-50%, -50%);
    user-select: none;
}