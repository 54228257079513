body, html, #root {
    width: max(100%, 960px);
    height: max(100%, 400px);
    margin: 0;
    display: flex;
    flex-direction: column;
}

img {
    -webkit-user-drag: none;
    user-drag: none;
}

.middle-container {
    position: absolute;
    display: flex;
    top: 60px;
    width: max(100%, 960px);
    height: calc(max(100%, 400px) - 60px);
}